<template>
    <div class="bg-[#F0F2F5] layout-grid h-screen w-screen">
        <header
            class="bg-[#0345D1] flex items-center pl-4 pr-8 col-span-3"
        >
            <img src="/static/image/logo-navbar.png" style="height: 24px; width: 172px;">
            <AntDropDown>
                <div class="cursor-pointer inline-flex items-center justify-center text-sm ml-auto mr-0 hover:bg-[#252A3D] p-2">
                    <div
                        class="rounded-full w-6 h-6 bg-[#FFD8BF] text-[#FA541C] inline-flex items-center justify-center text-sm ml-auto mr-0"
                    >
                        {{ auth.supplierCode[0].toUpperCase() }}
                    </div>
                    <div class="ml-2 text-sm text-neutral-1" @click.prevent>
                        {{ auth.supplierCode }}
                    </div>
                </div>
                <template #overlay>
                    <ul class="bg-neutral-1 rounded-sm shadow py-1 h6 mt-[4px]">
                        <li class="px-3 py-1 hover:bg-[#F5F5F5] cursor-pointer" @click="logout">
                            <Icon :name="'logout'" /> &nbsp;Logout
                        </li>
                    </ul>
                </template>
            </AntDropDown>
        </header>


        <div class="flex" style="height: calc(100vh - 48px); width: 100vw;">
            <Sidebar />

            <main class="bg-neutral-1 min-w-0 grow" :class="(useNew || raw) ? '' : 'ml-6 mt-6 mr-6'">
                <template v-if="useNew">
                    <div class="w-full h-full">
                        <div
                            class="h-[72px] flex items-center bg-neutral-1 relative"
                            :class="backButton ? 'pl-4 pr-6' : 'px-6'"
                            style="clip-path: inset(0 0 -10px 0)"
                        >
                            <InventoryBagCardButton
                                v-if="backButton"
                                icon="arrow-left"
                                class="mr-2"
                                @click="$router.back()"
                            />
                            <slot name="title" />
                        </div>

                        <div class="bg-[#F0F2F5] pt-6 px-6" style="height: calc(100vh - 72px)">
                            <slot />
                        </div>
                    </div>
                </template>
                <slot v-else />
            </main>

            <div v-if="$slots.right" ref="rightSidebar" class="ml-2.5 mt-6 flex overflow-hidden">
                <div
                    class="nod"
                    @click="toggleRightSidebar()"
                >
                    <div class="w-full h-full flex items-center justify-center">
                        <Icon
                            name="chevron-left"
                            class="transition-transform"
                            style="width: 6px"
                            :class="rightSidebarCollapsed ? '' : 'rotate-180'"
                        />
                    </div>
                </div>

                <div
                    ref="rightSidebarContainer"
                    class="bg-neutral-1 overflow-y-auto overflow-x-hidden h-full flex-1 pt-4 px-6 whitespace-nowrap"
                >
                    <slot name="right" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useMotion } from '@vueuse/motion';
    import { Dropdown as AntDropDown } from 'ant-design-vue';
    import 'ant-design-vue/dist/antd.css'
    import {
        templateRef,
        useToggle,
        breakpointsTailwind,
        useBreakpoints,
    } from '@vueuse/core';

    import { useAuth } from '~/store/auth';

    const auth = useAuth();

    const router = useRouter();


    defineProps({
        raw: {
            type: Boolean,
            default: false,
        },
        useNew: {
            type: Boolean,
            default: false,
        },
        backButton: {
            type: Boolean,
            default: false,
        },
    });

    const rightSidebarContainerRef = templateRef('rightSidebarContainer');
    const rightSidebarMotion = useMotion(templateRef('rightSidebar'), {
        initial: {
            width: '408px',
            transition: {
                type: 'keyframes',
                duration: 300,
            },
        },
        compact: {
            width: '250px',
            transition: {
                type: 'keyframes',
                duration: 300,
            },
        },
        collapsed: {
            width: '22px',
            transition: {
                type: 'keyframes',
                duration: 300,
            },
        },
    });

    const [rightSidebarCollapsed, toggleRightSidebar] = useToggle(false);
    const smaller = useBreakpoints(breakpointsTailwind).smaller('lg');
    watch([smaller, rightSidebarCollapsed], ([newSize, newCollapsed]) => {
        rightSidebarMotion.apply(newCollapsed ? 'collapsed' : (newSize ? 'compact' : 'initial'));
        if (rightSidebarContainerRef.value) {
            if (newCollapsed) {
                rightSidebarContainerRef.value.style.minWidth = `${rightSidebarContainerRef.value.getBoundingClientRect().width}px`;
            } else {
                rightSidebarContainerRef.value.style.minWidth = newSize ? '250px' : '408px';
            }
        }
    });
    const logout = () => {
        auth.logout()
        navigateTo(`/`);
    }
</script>

<style lang="postcss" scoped>
    .layout-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 48px 1fr;
    }

    .nod {
        @apply self-center w-3.5 h-10 grow-0 shrink-0 cursor-pointer;

        &::before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 8px 14px;
            border-color: transparent transparent #ffffff transparent;
        }

        &::after {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 0 14px;
            border-color: #ffffff transparent transparent transparent;
        }

        & > * {
            width: 14px;
            @apply bg-neutral-1;
        }
    }
</style>
