import { defineStore } from 'pinia';

export const useLayout = defineStore('layout', {
    state: () => ({
        leftSidebarCollapsed: false,
    }),
    actions: {
        toggleLeftSidebar(value?: boolean) {
            this.leftSidebarCollapsed = value ?? !this.leftSidebarCollapsed;
        },
    },
})
