<template>
    <aside ref="wrapper" class="bg-neutral-1 text-sm flex flex-col flex-shrink-0 z-10">
        <template v-for="item in items" :key="item.icon">
            <template v-if="item.children">
                <NuxtLink
                    v-if="item.children"
                    class="sidebar-item mt-1 flex-wrap"
                    active-class="text-primary-6"
                >
                    <Icon :name="item.icon" :size="collapsed ? 'default' : 'sm'" />

                    <span class="overflow-hidden min-w-0 ml-2">
                        {{ item.label }}
                    </span>
                </NuxtLink>
                <NuxtLink
                    v-for="child in item.children"
                    class="sidebar-item mt-1"
                    active-class="text-primary-6 bg-primary-1 border-r-2"
                    :key="child.label"
                    :to="child.to"
                >
                    <span class="overflow-hidden min-w-0 ml-6">
                        {{ child.label }}
                    </span>
                </NuxtLink>
            </template>
            <NuxtLink
                v-else
                :to="item.to"
                class="sidebar-item mt-1"
                active-class="text-primary-6 bg-primary-1 border-r-2"
            >
                <Icon :name="item.icon" :size="collapsed ? 'default' : 'sm'" />

                <span class="overflow-hidden min-w-0 ml-2 whitespace-nowrap">
                    {{ item.label }}
                </span>
            </NuxtLink>
        </template>

        <button
            class="appearance-none border-t border-[#0000000f] mt-auto sidebar-item"
            @click="toggle()"
        >
            <Icon :name="collapsed ? 'sidebar-collapsed' : 'sidebar-expanded'" />
        </button>
    </aside>
</template>

<script lang="ts" setup>
    import { useMotion } from '@vueuse/motion'
    import { useLayout } from '~/store/layout';

    const wrapper = ref<HTMLElement>();
    const motionInstance = useMotion(wrapper, {
        initial: {
            width: '208px',
            transition: {
                type: 'keyframes',
                duration: 300,
            },
        },
        collapsed: {
            width: '48px',
            transition: {
                type: 'keyframes',
                duration: 300,
            },
        },
    });

    const layout = useLayout();
    const collapsed = computed(() => layout.leftSidebarCollapsed);
    const toggle = () => layout.toggleLeftSidebar();

    watch(collapsed, (newVal, oldVal?: boolean) => {
        const variant = newVal ? 'collapsed' : 'initial';

        if (oldVal == null) {
            motionInstance.set(variant);
        } else {
            motionInstance.apply(variant);
        }
    }, { immediate: true });

    const items: Array<{
        icon: string,
        label: string,
        to: string,
    } | {
        label: string,
        icon: string,
        children?: Array<{ label: string, to: string }>
    }> = [
        { icon: 'file-done', label: 'Survey', to: '/home/survey' },
        { icon: 'gage', label: 'Dashboard', to: '/home/dashboard' },
        {
            label: 'Inventory',
            icon: 'unordered-list',
            to: '/home/stock/inventory',
        },
        { icon: 'tag', label: 'Labels', to: '/home/qr' },
        { icon: 'upload', label: 'Report Uploads', to: '/home/reports' },
    ];
</script>

<style scoped>
    aside {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    }

    .sidebar-item {
        @apply transition-colors duration-300 text-[#000000d9];
        @apply h-10 flex items-center px-4 hover:text-primary-6 active:bg-primary-1 cursor-pointer;
    }
</style>
